.header-top {
    padding: 0px 0px 4px;;
}
.header-top .logo{
    max-width: 265px;
}
.header-top .logo:hover{
    -webkit-animation: swing 1s ease;
    animation: swing 1s ease;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
@-webkit-keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
       transform: translateX(-5px);
    } 
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.header-top .social-media-link{
    float: right;
    margin-bottom: 0px;
    margin-top: 20px;
}
.header-top:before{
    background: linear-gradient(135deg, #000758 7px, transparent 0), linear-gradient(-135deg, #000ead 7px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 16px 32px;
    content: " ";
    display: block;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 12px;
}