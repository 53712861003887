#root{
    position: relative;
    min-height: 100vh;
    padding-bottom: 77px;
}

@font-face {
    font-family: Buenos;
    src: url(../src/fontfamily/BuenosAiresTrial-Regular.otf);
}
body{
    /* background-image: url(../src/images/main-bg4.png);
    background-image: url(../src/images/main-bg4.jpg); */
    background-image: url(../src/images/foggy-birds1.png);
    background-attachment: fixed;
    /* background-size: 263px; */
    font-family: Buenos;
}

nav button{
    background-color: #ff3d5d;
    background-image: linear-gradient(#ff3d5d 0%,#ff9595 , #ff3d5d 60%);
    color: #fff;
    border: none;
    padding: 2px 10px 6px;

}
.form-outer .btn, .btn-theme, .home-form-booking .btn{
    margin-bottom: 10px;
    background-color: #000758 !important;
    color: #fff;
    margin-right: 10px;
    border: 5px solid transparent;
    -moz-border-image: url(../src/images/border-img.png) 30 round;
  -webkit-border-image: url(../src/images/border-img.png) 30 round; /* Safari 3.1-5 */
  -o-border-image: url(../src/images/border-img.png) 30 round; /* Opera 11-12.1 */
  border-image: url(../src/images/border-img.png) 30 round;
  background-image: linear-gradient(#000758 0%,#0068b2 , #00075B 60%);
  border-radius: 0;
}
.form-outer button:hover, .btn-theme:hover, .home-form-booking .btn:hover{
  background-color: #00a9e2 !important;
  background-image: linear-gradient(#005ea9 0%,#54a4dc , #005ea9 60%);
  color: #fff;
}
.btn-theme-danger{
    margin-bottom: 10px;
    background-color: #ff0000 !important;
    background-image: linear-gradient(#ff0000 0%,#ff9595 , #ff0000 60%);
    color:#fff;
    border-radius: 0;
    margin-right: 10px;
    border: 5px solid transparent;
  -webkit-border-image: url(../src/images/border-img.png) 30 round; /* Safari 3.1-5 */
  -o-border-image: url(../src/images/border-img.png) 30 round; /* Opera 11-12.1 */
  border-image: url(../src/images/border-img.png) 30 round;
}
.btn-theme-danger:hover{
    background-color:#e00000 !important;
    background-image: linear-gradient(#e00000 0%,#ff9595 , #e00000 60%);
    color: #fff;
}
.home-form-booking{
    background-color: #fff;
    border-bottom: 5px solid #000b88;;
}
.home-form-booking .btn{
    padding: 4px 23px 6px;
    font-size: 1.5em;
    margin: 30px auto;
}
.news-section{
    /* background-color: grey; */
    border-radius: 0px;
}
.news-section .image-box{
    float: left;
    width: 150px;
}
.news-section .image-box .img-inner{
    width: 100%;
    height: 150px;
}
.news-section .image-box img{
    width: 100%;
    border-style: none;
    box-shadow: 1px 2px 6.5px #a2a2a2;
    object-fit: cover;
    height: 100%;
    border: 5px solid #fff;
    transition: all 0.3s ease-out;
  }
.news-section li:hover .image-box img{
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transform: scale(1.1, 1.1);
    transition: all 0.2s ease-out;
}

.news-section .content-box{
    float: left;
    padding-left: 20px;
}
.theme-color-dark {
    background-color: #000758!important;
}
.text-color-blue{
    color: #000758!important;
}
.form-outer{
    box-shadow: 1px 2px 6.5px #d0d0d0;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #dedcdc;
}
.form-outer label{
    color: #ff3d5d;
    font-weight: 500;
}
.form-outer .form-control{
    border-radius: 0px;
}
.contact-right-section i{
    background-color: #ff3d5d;
    color: #fff;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding-top: 11px;
    font-size: 19px;
    box-shadow: 1px 2px 6.5px #504f4f;
    margin-right: 10px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.contact-right-section i:hover{
   /* box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22); */
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

}
.contact-right-section p{
    margin-bottom: 0;
    font-weight: 500;
}
.social-media-link p{
    background-color: #fff;
}
.social-media-link i{
    border: 1px solid #fff;
    border-radius: 0;
    text-align: center;
    font-weight: bold;
    margin-right: 0;
    padding-top: 7px;
    font-size: 24px;
    width: 40px;
    height: 40px;
    box-shadow: none;
    border: 1px solid #e0e3e7;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%;
    
}
.social-media-link i:hover{
    box-shadow: 0px 0px 10.5px #9c9c9c;
    /* box-shadow: 0 0 0 8px rgba(255,255,255,0.3); */
    animation: fa-spin 2s infinite linear;
}
.social-media-link i.fa-instagram{
    background: -webkit-linear-gradient(#ff5757 , #bd00de);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.social-media-link i.fa-facebook-square{
    color: #395499;
    background-color: #fff;
}
.social-media-link i.fa-twitter{
    color: #1c9deb;
    background-color: #fff;
}
.social-media-link i.fa-youtube{
    color: #f70000;
    background-color: #fff;
}
.left-navigationbar{
    float: left;
    /* background-color: #fff; */
    padding: 20px 0px;
}
.left-navigationbar ul{
    padding: 0;
    list-style-type: none;
    margin: 0;
}
.left-navigationbar ul li{
    padding: 6px 15px;
    border-bottom: 1px solid #e6e6e6;
}
.left-navigationbar ul li a{
    color: #000758;
    font-size: 1.1em;
    font-weight: 500;
}
.left-navigationbar ul li a:hover{
    text-decoration: none;
}
.left-navigationbar ul li a i{
    color: #ff3d5d;
    font-size: 1.2em;
    padding-right: 8px;
}
.admin-content-box{
    background-color: #ecebeb;
    min-height: 80vh;
}
.add-pet-box{
    text-align: center;
    float: left;
    width: 100%;
    border:1px solid #ced4da;
    padding: 5px;
    margin-bottom: 10px;
}
.add-pet-box .btn{
    border-radius: 50%;
    border: 2px solid #e66f6f;
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0;
    padding: 0;
}
.add-pet-box .btn-left{
    float: left;
}
.add-pet-box .btn-right{
   float: right;
}
.add-pet-box label{
    padding-top: 10px;
}
.custom-span{
        font-weight: bold;
        background-color: #00075b;
        min-width: 50px;
        display: inline-block;
        text-align: center;
        padding: 0 10px;
        color: #fff;
        box-shadow: 1px 2px 6.5px #b3b3b3;
}
.payment-box table{
    box-shadow: 1px 2px 6.5px #d0d0d0;
    background-color: #fff;
    border: 1px solid #dedcdc;
}
.payment-box .table th,
.payment-box .table strong{
    color: #ff3d5d;
}
.aboutus-banner{
    background-color: grey;
    background-image: url(../src/images/bg-2.jpg);
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background-size: cover;
    padding: 100px 0px;
}
.aboutus-banner .main-heading .light:after{
    border-top: 1px solid #a2a2a200;
}
.services-page .card{
    border-radius: 0px;
    /* border-radius: 40px; */
}
.services-page .card img{
    padding: .25rem;
    background-color: #fff;
    /* border-radius: 40px; */
}
.services-page .card-body{
    padding-top: 0;
    
}
.services-page .card-title{
    text-align: center;
    background-color: #ff3d5d;
    background-image: linear-gradient(#ff3d5d 0%,#ff9595 , #ff3d5d 60%);
    padding: 10px;
    font-size: 1.2rem;
    color: #fff;
    margin: 0px -20px 15px;
}
.services-bottom-section{
    background-color: grey;
    background-image: url(../src/images/services-bottom-bg.jpg);
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background-size: cover;
    color: #fff;
    margin-top: 3rem;
}
.admin-booking ul{
    padding: 0;
    list-style-type: none;
}
.admin-booking ul li{
    border: 1px solid #e7e7e7;
    padding: 20px 20px 5px;
    margin-bottom: 20px;
    background-color: white;
}
@media only screen and (max-width: 992px) {
    .navbar-dark .navbar-nav .nav-link {
        font-size: 14px;
        padding-right: .3rem;
        padding-left: .3rem;
    }
    
}
@media only screen and (max-width: 768px) {
    .navbar-dark .navbar-nav li{
        border-bottom: 1px solid rgba(132, 132, 132, 0.56);
    }
    .aboutus-banner{
        padding: 50px 0px;
    }
    .left-navigationbar{
        width: 100%;
    }

}
@media only screen and (max-width: 500px) {
    .header-top .social-media-link{
        margin-top: 10px;
    }
    .header-top .social-media-link i{
        font-size: 16px;
        width: 27px;
        height: 27px;
        padding-top: 5px;
    }
    .header-top .list-inline-item{
        margin-right: 0.3rem;
    }
    .news-section .image-box{
        float: initial;
        margin: 0 auto;
    }
    .news-section .content-box{
        float: initial;
        padding-left: 0;
        text-align: center;
    }
  }
  @media only screen and (max-width: 400px) {
    .header-top .list-inline-item {
        margin-right: 0.2rem;
    }
    .header-top .social-media-link i{
        width: 25px;
        height: 25px;
    }

  }
  .profile-box img{
    max-width: 200px;
    border-radius: 50%;
    border: 5px solid #ff3d5d;
    box-shadow: 1px 2px 6.5px #000;
    transition: all 0.3s ease-out;

  }
  .profile-box img:hover{
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transform: scale(0.9, 0.9);
    transition: all 0.2s ease-out;
  }
  .news-detail{
      padding: 20px;
  }
  .news-detail img{
    box-shadow: 1px 2px 6.5px #000;
    width: 100%;
    max-width: 450px;
    border: 5px solid #fff;
    margin-bottom: 40px;
    transition: all 0.3s ease-out;
  }
  .news-detail img:hover{
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transform: scale(1.1, 1.1);
    transition: all 0.2s ease-out;
  }
  /* .image-url-news{

  } */
  /* services */
.services-page .card img
  {
      transition: all 0.3s ease-out;
  }
.services-page .card:hover img{
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transform: scale(0.9, 0.9);
    transition: all 0.2s ease-out;
}

/* .navbar-toggler-icon{
    width: 1em;
    height: 1em;
}*/
.navbar-toggler{
    padding: .20rem .75rem;
} 

.image-url-news strong{
    overflow: hidden;
    display: inline-block;
}
.image-url-news span{
    max-width: 200px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}