#carouselExampleFade .carousel-control-next-icon,
#carouselExampleFade .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

#carouselExampleFade .carousel-control-prev-icon {
   left: -35px;
  position: absolute;
  color: #000;
  width: 15px;
}

#carouselExampleFade .carousel-control-next-icon {
  right: -35px;
  position: absolute;
  color: #000;
  width: 15px;
}
#carouselExampleFade .carousel-inner img{
  height: auto;
  border-radius: 0;
 
}

#carouselExampleFade .pet-name{
  text-align: center;
  background-color: #ff3d5d;
  background-image: linear-gradient(#ff3d5d 0%,#ff9595 , #ff3d5d 60%);
  padding: 10px;
  font-size: 1.2rem;

}
.image-slider-box{
  background-color: grey;
  background-image: url(../../images/services-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .image-slider-box{ 
  overflow: hidden;
}
#carouselExampleFade .pet-name{
  font-size: 10px;
  padding: 5px;
}
}