nav{
    background-image: linear-gradient(#000758 0%,#0068b2 , #00075B 60%);
}
nav .decor_semicircle {
    width: 100%;
    height: 10px;
    background: url(../../images/semi-circle.png);
    position: absolute;
    z-index: 1;
    background-size: contain;
    left: 0;
    bottom: -10px;
}
.navbar-dark .navbar-nav .nav-link{
    color:#fff;
}
