#myTestimonial{
    margin: 30px 0px;
  }
  #myTestimonial .carousel-inner{
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
  }
  #myTestimonial  .carousel-inner img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    border: 5px solid #000b88;
    margin: 10px auto;
    box-shadow: 1px 2px 6.5px #000;
    background-color: #fff;
    padding: 10px;
  }
  #myTestimonial .carousel-indicators{
    position: relative;
  }
  
  #myTestimonial .carousel-control-next,#myTestimonial .carousel-control-prev{
    opacity: 1;
  }
  #myTestimonial .fa-angle-left:before,
  #myTestimonial .fa-angle-right:before
  {
    color: #fff;
    background-color: #ff3d5d;
    background-image: linear-gradient(#ff3d5d 0%,#ff9595 , #ff3d5d 60%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    padding-top: 7px;
    border: 4px solid #fff;
    box-shadow: 1px 2px 6.5px #000;
  }
  
  #myTestimonial .fa-angle-left:hover:before,
  #myTestimonial .fa-angle-right:hover:before{
    background-color: #000b88;
    background-image: linear-gradient(#000758 0%,#0068b2 , #00075B 60%);
  }
  #myTestimonial .carousel-indicators li{
    background-color: #ff3d5d;
    width: 10px;
      height: 10px;
      border-radius: 50%;
  }