footer{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem 0;
    background-image: url(../../images/footer-bg.png);
    background-position: top;
    color: #fff;
    background-size: cover;
}